import { useState } from "react";
import * as UnIcons from "@iconscout/react-unicons";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LogoBlack, LogoWhite } from "../../assets";
export const NavBar = (state) => {
    const { changeNavBar, theme, setTheme } = state;
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const handleThemeChange = () => {
        setTheme(theme === "light" ? "dark" : "light");
        document.body.classList[theme === "light" ? "add" : "remove"](
            "dark-theme"
        );
    };

    const handleClickToggle = () => {
        setShow(!show);
    };
    const handleClickHide = () => {
        setShow(false);
    };
    return (
        <>
            <nav className="nav container">
                <div>
                    <Link
                        to="/"
                        className="nav__logo"
                        style={{
                            color: `${changeNavBar ? "#232925" : "#f1f3f2"}`,
                        }}
                    >
                        <img
                            style={{ height: "65px" }}
                            src={`${changeNavBar ? LogoBlack : LogoWhite}`}
                            alt="logo"
                        />
                    </Link>
                </div>
                <div
                    className={`nav__menu ${show ? "show-menu" : ""}`}
                    id="nav-menu"
                >
                    <ul className="nav__list">
                        <li className="nav__item" onClick={handleClickHide}>
                            <NavLink
                                to="/solution"
                                className="nav__link"
                                style={{
                                    color: `${
                                        changeNavBar ? "#232925" : "#f1f3f2"
                                    }`,
                                }}
                            >
                                <>
                                    <UnIcons.UilEstate className="nav__icon" />
                                    {t("navBar.solution")}
                                </>
                            </NavLink>
                        </li>
                        <li className="nav__item" onClick={handleClickHide}>
                            <NavLink
                                to="/about"
                                className="nav__link"
                                style={{
                                    color: `${
                                        changeNavBar ? "#232925" : "#f1f3f2"
                                    }`,
                                }}
                            >
                                <UnIcons.UilSuitcase className="nav__icon" />
                                {t("navBar.about")}
                            </NavLink>
                        </li>
                        {/* <li className="nav__item" onClick={handleClickHide}>
                        <NavLink
                            to="/company"
                            className="nav__link"
                            style={{
                                color: `${
                                    changeNavBar ? "#232925" : "#f1f3f2"
                                }`,
                            }}
                        >
                            <>
                                <UnIcons.UilUser className="nav__icon" />
                                {t("navBar.company")}
                            </>
                        </NavLink>
                    </li>
                    <li className="nav__item" onClick={handleClickHide}>
                        <NavLink
                            to="/career"
                            className="nav__link"
                            style={{
                                color: `${
                                    changeNavBar ? "#232925" : "#f1f3f2"
                                }`,
                            }}
                        >
                            <>
                                <UnIcons.UilUser className="nav__icon" />
                                {t("navBar.career")}
                            </>
                        </NavLink>
                    </li> */}
                        <li className="nav__item " onClick={handleClickHide}>
                            <Link
                                to="/contact"
                                className="nav__link button__navbar"
                            >
                                <>
                                    <UnIcons.UilUserPlus className="nav__icon" />
                                    {t("navBar.contact")}
                                </>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="nav__btns">
                    {theme === "dark" ? (
                        <UnIcons.UilSun
                            className="change-theme"
                            id="theme-button"
                            onClick={handleThemeChange}
                            style={{
                                color: `${
                                    changeNavBar ? "#232925" : "#f1f3f2"
                                }`,
                            }}
                        />
                    ) : null}
                    {theme === "light" ? (
                        <UnIcons.UilMoon
                            className="change-theme"
                            id="theme-button"
                            onClick={handleThemeChange}
                            style={{
                                color: `${
                                    changeNavBar ? "#232925" : "#f1f3f2"
                                }`,
                            }}
                        />
                    ) : null}
                    <div
                        className="nav__toggle"
                        id="nav-toggle"
                        onClick={handleClickToggle}
                    >
                        <UnIcons.UilBars
                            style={{
                                color: `${
                                    changeNavBar ? "#232925" : "#f1f3f2"
                                }`,
                            }}
                        />
                    </div>
                </div>
            </nav>
            {show ? (
                <div className="nav__menu__mobile">
                    <ul className="nav__list__mobile">
                        <li
                            className="nav__item__mobile"
                            onClick={handleClickHide}
                        >
                            <NavLink
                                to="/solution"
                                className="nav__link__mobile"
                                style={{
                                    color: `${
                                        changeNavBar ? "#232925" : "#f1f3f2"
                                    }`,
                                }}
                            >
                                <UnIcons.UilEstate className="nav__icon" />
                                {t("navBar.solution")}
                            </NavLink>
                        </li>
                        <li
                            className="nav__item__mobile"
                            onClick={handleClickHide}
                        >
                            <NavLink
                                to="/about"
                                className="nav__link__mobile"
                                style={{
                                    color: `${
                                        changeNavBar ? "#232925" : "#f1f3f2"
                                    }`,
                                }}
                            >
                                <UnIcons.UilSuitcase className="nav__icon" />
                                <p>{t("navBar.about")}</p>
                            </NavLink>
                        </li>
                        <li
                            className="nav__item__mobile"
                            onClick={handleClickHide}
                        >
                            <Link
                                to="/contact"
                                className="nav__link__mobile button__navbar__mobile"
                            >
                                <UnIcons.UilUserPlus className="nav__icon" />
                                {t("navBar.contact")}
                            </Link>
                        </li>
                    </ul>
                </div>
            ) : null}
        </>
    );
};
