import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { techs } from "../../assets/tech";
import { Autoplay } from "swiper";
import "swiper/css/bundle";

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

export const TechStack = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <section className="section container container__data">
            <h3 className="section__title"> {t("home.tech.title")}</h3>
            <p className="section__description">{t("home.tech.subtitle")}</p>
            <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}
                className="testimonial__container container"
            >
                {window.screen.width < 536 ? (
                    <Swiper
                        modules={[Autoplay]}
                        slidesPerView={2}
                        autoplay={{ delay: 2000 }}
                        loop={true}
                    >
                        {techs.map((tech, index) => (
                            <SwiperSlide key={index}>
                                <div className="swiper__data">
                                    <img
                                        src={tech.logo}
                                        alt="tech_logo"
                                        className="swiper__img"
                                    />
                                    <h3 className="swiper__title">
                                        {tech.name}
                                    </h3>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <Swiper
                        cssMode={true}
                        navigation={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        mousewheel={true}
                        keyboard={true}
                        slidesPerView={5}
                        spaceBetween={0}
                        loop={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    >
                        {techs.map((tech, index) => (
                            <SwiperSlide key={index}>
                                <div className="swiper__data">
                                    <img
                                        src={tech.logo}
                                        alt="tech_logo"
                                        className="swiper__img"
                                    />
                                    <h3 className="swiper__title">
                                        {tech.name}
                                    </h3>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </motion.div>
        </section>
    );
};
