import { useTranslation } from "react-i18next";
import * as UnIcons from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

export const FAQ = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <section className="section container container__data">
            <div>
                <h3 className="section__title">{t("solution.faq.title")}</h3>
                <p className="section__description">
                    {t("solution.faq.subtitle")}
                </p>
            </div>
            <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}
                class="faq__data"
            >
                <div class="faq__cards">
                    <div class="faq__card">
                        <div className="faq__icon-wrapper">
                            <UnIcons.UilArrowsHAlt class="faq__icon" />
                        </div>
                        <div class="faq__title">{t("solution.faq.q1")}</div>
                        <div class="faq__description">
                            {t("solution.faq.r1")}
                        </div>
                    </div>
                    <div class="faq__card">
                        <div className="faq__icon-wrapper">
                            <UnIcons.UilBan class="faq__icon" />
                        </div>
                        <div class="faq__title">{t("solution.faq.q2")}</div>
                        <div class="faq__description">
                            {t("solution.faq.r2")}
                        </div>
                    </div>
                    <div class="faq__card">
                        <div className="faq__icon-wrapper">
                            <UnIcons.UilFileRedoAlt class="faq__icon" />
                        </div>
                        <div class="faq__title">{t("solution.faq.q3")}</div>
                        <div class="faq__description">
                            {t("solution.faq.r3")}
                        </div>
                    </div>
                    {/* <div class="faq__card">
                        <div className="faq__icon-wrapper">
                            <UnIcons.UilTransaction class="faq__icon" />
                        </div>
                        <div class="faq__title"> {t("solution.faq.q4")}</div>
                        <div class="faq__description">
                            {t("solution.faq.r4")}
                        </div>
                    </div> */}
                </div>

                <div class="faq__bottom-bar ">
                    <span class="faq__title"> {t("solution.faq.q5")}</span>
                    <p class="faq__bottom-bar-text">{t("solution.faq.r5")}</p>
                    <Link to="/contact" className="button button--flex">
                        {t("navBar.contact")}
                        <UnIcons.UilMessage className="button__icon" />
                    </Link>
                </div>
            </motion.div>
        </section>
    );
};
