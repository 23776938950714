import React, { useRef, useEffect } from "react";
import { HeroArea } from "../../components/heroArea";

import { IT } from "../../assets/expertise";
import { useTranslation } from "react-i18next";
import "./expertise.css";

export const Dedicated = () => {
  const { t } = useTranslation();
  const section = useRef(null);
  useEffect(() => {
    section.current.scrollIntoView();
  }, []);
  return (
    <>
      <HeroArea />

      <div ref={section} className="service--container container">
        <h2>{t("service.it.title1")}</h2>
        <div className="service--content">
          <p className="paragraph">{t("service.it.paragraph1")}</p>
          <img src={IT} alt="it" className="service--img" />
        </div>
        <br />
        <div className="service--second--content">
          <h3>{t("service.methodology")}</h3>
          <h4>1 - {t("service.it.title2")}</h4>
          <p className="paragraph">{t("service.it.paragraph2")}</p>
          <h4>2 - {t("service.it.title3")}</h4>
          <p className="paragraph">{t("service.it.paragraph3")}</p>
          <h4>3 - {t("service.it.title4")}</h4>
          <p className="paragraph">{t("service.it.paragraph4")}</p>
          <h4>4 - {t("service.it.title5")}</h4>
          <p className="paragraph">{t("service.it.paragraph5")}</p>
          <h4>5 - {t("service.it.title6")}</h4>
          <p className="paragraph">{t("service.it.paragraph6")}</p>
        </div>
      </div>
    </>
  );
};
