import * as UnIcons from "@iconscout/react-unicons";
import { useTranslation } from "react-i18next";
import { Alert, TextField } from "@mui/material";
import emailjs from "@emailjs/browser";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState, useRef } from "react";

const boxVariant = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.3, duration: 1 },
  },
  hidden: { opacity: 0, y: -100 },
};

export const ContactForm = () => {
  const form = useRef();
  const { t } = useTranslation();
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState("");
  const [serverError, setServerError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !message) {
      setError(true);
    } else {
      emailjs
        .sendForm(
          "service_akl4uno",
          "template_du4ulxj",
          form.current,
          "QsQhwPLeUS0lFLg7n"
        )
        .then(
          () => {
            setName("");
            setTel("");
            setCompanyName("");
            setEmail("");
            setMessage("");
            setServerError(false);
            setError(false);
            setSuccess(true);
          },
          () => {
            setError(false);
            setSuccess(false);
            setServerError(true);
          }
        );
    }
  };
  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <section className="section" id="contact">
      <h2 className="section__title">{t("contact.title")}</h2>
      <span className="section__subtitle">{t("navBar.contact")}</span>
      <motion.div
        ref={ref}
        variants={boxVariant}
        initial="hidden"
        animate={control}
        className="contact__container container grid"
      >
        <div>
          <div className="contact__information">
            <UnIcons.UilPhone className="contact__icon" />
            <div>
              <a href="tel:+212-702-092-102" rel="noreferrer" target="_blank">
                <h3 className="contact__title">{t("contact.call")}</h3>
                <span className="contact__subtitle">+212 (702) 092-102</span>
              </a>
            </div>
          </div>
          <div className="contact__information">
            <UnIcons.UilEnvelope className="contact__icon" />
            <div>
              <a
                href="mailto:contact@smanovation.com"
                rel="noreferrer"
                target="_blank"
              >
                <h3 className="contact__title">Email</h3>
                <span className="contact__subtitle">
                  contact@smanovation.com
                </span>
              </a>
            </div>
          </div>
          <div className="contact__information">
            <UnIcons.UilMapMarker className="contact__icon" />
            <div>
              <a
                href="https://www.google.com/maps/place/Marrakech/@31.6346214,-8.0078531,12z/data=!3m1!4b1!4m5!3m4!1s0xdafee8d96179e51:0x5950b6534f87adb8!8m2!3d31.6294723!4d-7.9810845"
                rel="noreferrer"
                target="_blank"
              >
                <h3 className="contact__title">{t("contact.location")}</h3>
                <span className="contact__subtitle">
                  {t("contact.location2")}
                </span>
              </a>
            </div>
          </div>
        </div>
        <form className="contact__form grid" ref={form}>
          {serverError ? (
            <Alert
              className="mb-4"
              severity="error"
              onClose={() => {
                setServerError(false);
              }}
            >
              {t("contact.server.error")}
            </Alert>
          ) : null}
          {error ? (
            <Alert
              severity="error"
              onClose={() => {
                setError(false);
              }}
            >
              {t("contact.error")}
            </Alert>
          ) : null}{" "}
          {success ? (
            <Alert
              severity="success"
              onClose={() => {
                setSuccess(false);
              }}
            >
              {t("contact.success")}
            </Alert>
          ) : null}
          <div className="contact__inputs grid">
            <div className="contact__content">
              <label className="contact__label" htmlFor="name">
                {t("contact.name")}
              </label>
              <TextField
                className="contact__input"
                type="text"
                size="small"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    display: "none",
                  },
                }}
              />
            </div>
            <div className="contact__content">
              <label className="contact__label" htmlFor="email">
                Email
              </label>
              <TextField
                className="contact__input"
                type="email"
                size="small"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    display: "none",
                  },
                }}
              />
            </div>
          </div>
          <div className="contact__inputs grid">
            <div className="contact__content">
              <label className="contact__label" htmlFor="companyName">
                {t("contact.company")}
              </label>
              <TextField
                className="contact__input"
                type="text"
                size="small"
                name="companyName"
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    display: "none",
                  },
                }}
              />
            </div>
            <div className="contact__content">
              <label className="contact__label" for="telephone">
                {t("contact.tel")}
              </label>
              <TextField
                className="contact__input"
                type="tel"
                size="small"
                name="tel"
                id="telephone"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    display: "none",
                  },
                }}
              />
            </div>
          </div>
          <div className="contact__content">
            <label className="contact__label" for="message">
              {t("contact.message")}
            </label>
            <TextField
              className="contact__input"
              type="text"
              size="small"
              name="message"
              id="message"
              multiline
              rows={7}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                "& .MuiInputLabel-root": {
                  display: "none",
                },
              }}
            />
          </div>
          <div>
            <a
              href="/"
              className="button button--flex"
              type="submit"
              onClick={handleSubmit}
            >
              {t("contact.send")}
              <UnIcons.UilMessage className="button__icon" />
            </a>
          </div>
        </form>
      </motion.div>
    </section>
  );
};
