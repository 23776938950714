import Angular from "./angular.png";
import AWS from "./aws.png";
import Bootstrap from "./bootstrap.png";
import CPP from "./c++.png";
import Chai from "./chai.png";
import Deno from "./deno.png";
import DigitalOcean from "./digital ocean.png";
import Docker from "./docker.png";
import Express from "./express.png";
import Firebase from "./firebase.png";
import Flutter from "./flutter.png";
import GoogleCloud from "./google cloud.png";
import GraphQL from "./graphql.png";
import Kubernetes from "./kubernetes.png";
import Laravel from "./laravel.png";
import Material from "./material.png";
import Vue from "./vue.png";
import Mocha from "./mocha.png";
import Rails from "./rails.png";
import Django from "./django.png";
import Next from "./next.png";
import Flask from "./flask.png";
import Oracle from "./oracle.png";
import Symfony from "./symfony.png";
import Python from "./python.png";
import Nuxt from "./nuxt.png";
import ReactNative from "./react native.png";
import React from "./react.png";
import Redux from "./redux.png";
import Sass from "./sass.png";
import Spring from "./spring boot.png";
import Tailwind from "./tailwind css.png";
import Net from "./net.png";
import Nest from "./nest.png";
import Swift from "./swift.png";
import Kotlin from "./kotlin.png";

export const techs = [
    {
        logo: Sass,
        name: "Sass",
    },
    {
        logo: Tailwind,
        name: "Tailwind CSS",
    },
    {
        logo: Material,
        name: "MUI",
    },
    {
        logo: Bootstrap,
        name: "Bootstrap",
    },
    {
        logo: React,
        name: "React JS",
    },
    {
        logo: Vue,
        name: "Vue JS",
    },
    {
        logo: Angular,
        name: "Angular",
    },
    {
        logo: Redux,
        name: "Redux",
    },
    {
        logo: Nuxt,
        name: "Nuxt",
    },
    {
        logo: Next,
        name: "Next JS",
    },
    {
        logo: Python,
        name: "Python",
    },
    {
        logo: CPP,
        name: "C++",
    },
    {
        logo: Symfony,
        name: "Symfony",
    },
    {
        logo: Laravel,
        name: "Laravel",
    },
    {
        logo: Express,
        name: "Express",
    },
    {
        logo: Nest,
        name: "Nest JS",
    },
    {
        logo: Deno,
        name: "Deno",
    },
    {
        logo: Net,
        name: ".Net",
    },
    {
        logo: Spring,
        name: "Spring Boot",
    },
    {
        logo: Django,
        name: "Django",
    },
    {
        logo: Flask,
        name: "Flask",
    },
    {
        logo: Rails,
        name: "Ruby On Rails",
    },
    {
        logo: GraphQL,
        name: "GraphQL",
    },
    {
        logo: Chai,
        name: "Chai",
    },
    {
        logo: Mocha,
        name: "Mocha",
    },
    {
        logo: ReactNative,
        name: "ReactNative",
    },
    {
        logo: Flutter,
        name: "Flutter",
    },
    {
        logo: Kotlin,
        name: "Kotlin",
    },
    {
        logo: Swift,
        name: "SWift",
    },
    {
        logo: Oracle,
        name: "Oracle",
    },
    {
        logo: AWS,
        name: "AWS",
    },
    {
        logo: DigitalOcean,
        name: "Digital Ocean",
    },
    {
        logo: Firebase,
        name: "Firebase",
    },
    {
        logo: GoogleCloud,
        name: "Google Cloud",
    },
    {
        logo: Docker,
        name: "Docker",
    },
    {
        logo: Kubernetes,
        name: "Kubernetes",
    },
];
