import {
    Tooltip,
    Button,
    IconButton,
    Link,
    Typography,
    Snackbar,
    Alert,
} from "@mui/material";
import { useState, forwardRef } from "react";
import { Gb, Fr, De } from "react-flags-select";
import i18n from "i18next";
import cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const styles = {
    btn: {
        borderRadius: "15px",
        color: "var(--title-color)",
    },
};

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
    return <Alert elevation={6} ref={ref} {...props} />;
});

export const TopNavBar = (state) => {
    const { changeNavBar } = state;
    const currentLanguageCode = cookies.get("i18next") || "fr";
    const [language, setLanguage] = useState(currentLanguageCode);
    const [open, setOpen] = useState(false);
    const handleClose = (event, reason?) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    return (
        <nav className="top-nav container">
            {language !== "fr" ? (
                <Tooltip
                    title="Changer la langue en Français"
                    placement="top"
                    arrow
                    enterDelay={500}
                    leaveDelay={500}
                    color="primary"
                >
                    <Button
                        style={styles.btn}
                        variant="text"
                        size="small"
                        startIcon={<Fr style={{ borderRadius: "6px" }} />}
                        disableElevation
                        disableRipple
                        sx={{
                            ":hover": {
                                backgroundColor: "transparent",
                            },
                        }}
                        onClick={() => {
                            i18n.changeLanguage("fr");
                            setLanguage("fr");
                            setOpen(true);
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            fontWeight="300"
                            sx={{ textTransform: "none" }}
                            style={{
                                color: `${
                                    changeNavBar ? "#232925" : "#f1f3f2"
                                }`,
                            }}
                        >
                            Français
                        </Typography>
                    </Button>
                </Tooltip>
            ) : null}
            {language !== "en" ? (
                <Tooltip
                    title="Change the language to english"
                    placement="top"
                    arrow
                    enterDelay={500}
                    leaveDelay={500}
                    color="primary"
                >
                    <Button
                        style={styles.btn}
                        variant="text"
                        size="small"
                        startIcon={<Gb style={{ borderRadius: "6px" }} />}
                        disableElevation
                        disableRipple
                        sx={{
                            ":hover": {
                                backgroundColor: "transparent",
                            },
                        }}
                        onClick={() => {
                            i18n.changeLanguage("en");
                            setLanguage("en");
                            setOpen(true);
                        }}
                    >
                        <Typography
                            fontWeight="300"
                            variant="subtitle2"
                            sx={{ textTransform: "none" }}
                            style={{
                                color: `${
                                    changeNavBar ? "#232925" : "#f1f3f2"
                                }`,
                            }}
                        >
                            English
                        </Typography>
                    </Button>
                </Tooltip>
            ) : null}
            {language !== "de" ? (
                <Tooltip
                    title="Sprache auf Deutsch umstellen"
                    placement="top"
                    arrow
                    enterDelay={500}
                    leaveDelay={500}
                    color="primary"
                >
                    <Button
                        style={styles.btn}
                        variant="text"
                        size="small"
                        startIcon={<De style={{ borderRadius: "6px" }} />}
                        disableElevation
                        disableRipple
                        sx={{
                            ":hover": {
                                backgroundColor: "transparent",
                            },
                        }}
                        onClick={() => {
                            i18n.changeLanguage("de");
                            setLanguage("de");
                            setOpen(true);
                        }}
                    >
                        <Typography
                            fontWeight="300"
                            variant="subtitle2"
                            sx={{ textTransform: "none" }}
                            style={{
                                color: `${
                                    changeNavBar ? "#232925" : "#f1f3f2"
                                }`,
                            }}
                        >
                            Deutsch
                        </Typography>
                    </Button>
                </Tooltip>
            ) : null}

            <Link
                href="https://www.linkedin.com/company/smanovation/"
                target="_blank"
            >
                <IconButton
                    aria-label="linkedin"
                    size="small"
                    sx={{
                        borderRadius: "15px",
                        color: `${changeNavBar ? "#232925" : "#f1f3f2"}`,
                        ":hover": {
                            backgroundColor: "transparent",
                            color: "#0072b1",
                            transition: "color 0.2s",
                        },
                    }}
                >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </IconButton>
            </Link>
            <Link href="https://twitter.com/smanovation" target="_blank">
                <IconButton
                    aria-label="twitter"
                    size="small"
                    sx={{
                        borderRadius: "15px",
                        color: `${changeNavBar ? "#232925" : "#f1f3f2"}`,
                        ":hover": {
                            backgroundColor: "transparent",
                            color: "#1DA1F2",
                            transition: "color 0.2s",
                        },
                    }}
                >
                    <FontAwesomeIcon icon={faTwitter} />
                </IconButton>
            </Link>
            <Snackbar
                open={open}
                autoHideDuration={3500}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <SnackbarAlert severity="success">
                    {language === "fr"
                        ? "La langue a été changée en Français avec succès"
                        : null}
                    {language === "de"
                        ? "Die Sprache wurde erfolgreich auf Deutsch umgestellt"
                        : null}
                    {language === "en"
                        ? "The language was successfully changed to English"
                        : null}
                </SnackbarAlert>
            </Snackbar>
        </nav>
    );
};
