/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Home1, Home2, Home3 } from "../../assets";
import { useTranslation } from "react-i18next";

const styles = {
    HeroAreaContainer: {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Home1})`,
    },
};

const titleVariants = {
    hidden: {
        y: "50vh",
        opacity: 0,
        scale: 0.25,
    },
    visible: {
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { delay: 1, type: "tween", duration: 1 },
    },
};

const contentVariants = {
    hidden: {
        y: "50vh",
        opacity: 0,
        scale: 0.25,
    },
    visible: {
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { delay: 2, type: "tween", duration: 1 },
    },
};

export const HeroArea = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState(1);
    let i = 1;
    let op = 1;
    const slideShow = () => {
        const box = document.getElementById("hero-area");
        setTimeout(slideShow, 15000);
        op += 1;
        if (op >= 2) {
            op = 1;
            i++;
            if (i > 3) {
                i = 1;
            }
            if (i === 1) {
                if (box != undefined) {
                    box.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Home1})`;
                }
                setContent(1);
            } else if (i === 2) {
                if (box != undefined) {
                    box.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Home2})`;
                }
                setContent(2);
            } else if (i === 3) {
                if (box != undefined) {
                    box.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Home3})`;
                }
                setContent(3);
            }
        }
        if (box != undefined) {
            box.style.opacity = op + "";
        }
    };
    const [show, setShow] = useState(true);
    const controlNavbar = () => {
        if (window.scrollY < window.innerHeight / 4) {
            setShow(true);
        } else {
            setShow(false);
        }
    };

    useEffect(() => {
        slideShow();
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.removeEventListener("scroll", controlNavbar);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <section
            id="hero-area"
            style={styles.HeroAreaContainer}
            className="home__container"
        >
            <div className="home__content">
                <motion.div
                    variants={titleVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <h1
                        id="title"
                        className="home__title"
                        style={{
                            opacity: `${show ? "1" : "0"}`,
                            transition: "opacity 0.2s ease-in",
                            color: "#fff",
                        }}
                    >
                        {content === 1 ? "Smanovation" : ""}
                        {content === 2 ? t("hero.title2") : ""}
                        {content === 3 ? t("hero.title3") : ""}
                    </h1>
                    <h3
                        id="subtitle"
                        className="home__subtitle"
                        style={{
                            opacity: `${show ? "1" : "0"}`,
                            transition: "opacity 0.2s ease-in",
                            color: "#bac4be",
                        }}
                    >
                        {content === 1 ? "Bridge to your Success" : ""}
                        {content === 2 ? t("hero.subtitle2") : ""}
                        {content === 3 ? t("hero.subtitle3") : ""}
                    </h3>
                </motion.div>
                <motion.div
                    variants={contentVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <p
                        id="content"
                        className="home__description"
                        style={{
                            opacity: `${show ? "1" : "0"}`,
                            transition: "opacity 0.4s ease-in",
                            color: "#bac4be",
                        }}
                    >
                        {content === 1 ? t("hero.content") : ""}
                        {content === 2 ? t("hero.content2") : ""}
                        {content === 3 ? t("hero.content3") : ""}
                    </p>
                </motion.div>
            </div>
        </section>
    );
};
