import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

export const Methodology = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <section className="section container container__data">
            <div>
                <h3 className="section__title">
                    {" "}
                    {t("solution.method.title")}
                </h3>
                <p className="section__description">
                    {t("solution.method.subtitle")}
                </p>
            </div>
            <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}
                className="methodology-info"
            >
                <div>
                    <span className="methodology-title">
                        1. {t("solution.method.1")}
                    </span>
                </div>
                <div>
                    <span className="methodology-title">
                        2. {t("solution.method.2")}
                    </span>
                </div>
                <div>
                    <span className="methodology-title">
                        3. {t("solution.method.3")}
                    </span>
                </div>
                <div>
                    <span className="methodology-title">
                        4. {t("solution.method.4")}
                    </span>
                </div>
                <div>
                    <span className="methodology-title">
                        5. {t("solution.method.5")}
                    </span>
                </div>
            </motion.div>
        </section>
    );
};
