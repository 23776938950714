import { Link } from "react-router-dom";
import { useEffect } from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { IconButton, Link as MuiLink } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { LogoBlack, LogoWhite } from "../../assets";

const boxVariant = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.3, duration: 1 },
  },
  hidden: { opacity: 0, y: 100 },
};

export const Footer = (props) => {
  const { t } = useTranslation();

  const control = useAnimation();
  const [ref, inView] = useInView();
  const date = new Date();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);
  return (
    <motion.footer
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      className="footer"
    >
      <div className="footer__container">
        <div>
          <Link to="/">
            <img
              style={{ height: "65px" }}
              src={props.theme === "light" ? LogoBlack : LogoWhite}
              alt="logo"
            />
          </Link>
        </div>
        <div className="footer__links">
          <h4 className="footer__title">{t("footer.links")}</h4>
          <ul className="footer__links">
            <li>
              <Link to="/solution" className="footer__link">
                {t("navBar.solution")}
              </Link>
            </li>
            <li>
              <Link to="/about" className="footer__link">
                {t("navBar.about")}
              </Link>
            </li>
            <li>
              <Link to="/contact" className="footer__link">
                {t("navBar.contact")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer__links">
          <h4 className="footer__title"> {t("footer.contact")}</h4>
          <ul className="footer__links">
            <li className="footer__link">
              <a
                href="mailto:contact@smanovation.com"
                target="_blank"
                rel="noreferrer"
                className="footer__link"
              >
                contact@smanovation.com
              </a>
            </li>
            <li>
              <a
                href="tel:+212-702-092-102"
                target="_blank"
                rel="noreferrer"
                className="footer__link"
              >
                +212 (702) 092-102
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__links">
          <h4 className="footer__title">{t("footer.address")}</h4>
          <ul className="footer__links">
            <li>
              <a
                href="https://www.google.com/maps/place/Marrakech/@31.6346214,-8.0078531,12z/data=!3m1!4b1!4m5!3m4!1s0xdafee8d96179e51:0x5950b6534f87adb8!8m2!3d31.6294723!4d-7.9810845"
                target="_blank"
                rel="noreferrer"
                className="footer__link"
              >
                Marrakech
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer__copy">
        <p>
          {t("footer.rights1")} <span style={{ fontSize: "18px" }}>&copy;</span>{" "}
          {date.getFullYear()} Smanovation. {t("footer.rights2")}.
        </p>
        <div>
          <MuiLink
            href="https://www.linkedin.com/company/smanovation/"
            target="_blank"
            style={{
              color: "var(--title-color)",
              marginRight: "20px",
              textDecoration: "none",
            }}
          >
            LinkedIn&nbsp;
            <IconButton
              aria-label="linkedin"
              size="small"
              sx={{
                borderRadius: "15px",
                color: "var(--title-color)",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "#0072b1",
                  transition: "color 0.2s",
                },
              }}
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </IconButton>
          </MuiLink>

          <MuiLink
            href="https://twitter.com/smanovation"
            target="_blank"
            style={{ color: "var(--title-color)", textDecoration: "none" }}
          >
            Twitter&nbsp;
            <IconButton
              aria-label="twitter"
              size="small"
              sx={{
                borderRadius: "15px",
                color: "var(--title-color)",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "#1DA1F2",
                  transition: "color 0.2s",
                },
              }}
            >
              <FontAwesomeIcon icon={faTwitter} />
            </IconButton>
          </MuiLink>
        </div>
      </div>
    </motion.footer>
  );
};
