import React, { useRef, useEffect } from "react";
import { ContactForm } from "./contactForm";
import { HeroArea } from "../../components/heroArea";

export const Contact = () => {
  const contactForm = useRef(null);
  useEffect(() => {
    contactForm.current.scrollIntoView();
  }, []);
  return (
    <>
      <HeroArea />
      <div ref={contactForm}>
        <ContactForm />
      </div>
    </>
  );
};
