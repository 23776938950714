import { useTranslation } from "react-i18next";

import { Offer1, Offer2 } from "../../assets";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

export const Offering = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <section className="section container container__data">
            <h3 className="section__title"> {t("home.offering.title")}</h3>
            <p className="section__description">
                {t("home.offering.subtitle")}
            </p>
            <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}
                className="offering__info"
            >
                <div className="offering__data">
                    <img
                        src={Offer1}
                        alt="what-we-offer"
                        className="offering__data-image"
                        id="cube-1"
                    />
                    <div className="offering__data-title-wrapper">
                        <span className="offering__data-title">
                            {t("home.offering.1")}
                        </span>
                    </div>
                </div>
                <div className="offering__data">
                    <img
                        src={Offer2}
                        alt="what-we-offer"
                        className="offering__data-image"
                        id="cube-2"
                    />
                    <div className="offering__data-title-wrapper">
                        <span className="offering__data-title">
                            {t("home.offering.2")}
                        </span>
                    </div>
                </div>
            </motion.div>
        </section>
    );
};
