import { HeroArea } from "../../components/heroArea";
import { Methodology } from "./Methodology";
import { Support } from "./Support";
import { Expertise } from "../../components/expertise";
// import { Testimonials } from "./Testimonials";
import { FAQ } from "./FAQ";

export const Solution = () => {
  return (
    <>
      <HeroArea />
      <Methodology />
      <Support />
      <Expertise />
      {/* <Testimonials /> */}
      <FAQ />
    </>
  );
};
