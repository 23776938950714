import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

export const Team = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <section className="section container container__data">
            <div>
                <h3 className="section__title"> {t("about.team.title")}</h3>
            </div>
            <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}
                className="history-info"
            >
                {t("about.team.subtitle")}
            </motion.div>
        </section>
    );
};
