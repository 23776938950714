import { TopNavBar } from "../topNavBar";
import { NavBar } from "../navBar";

import { useState, useEffect } from "react";

export const Header = (props) => {
    const [show, setShow] = useState(true);
    const [changeNavBar, setChangeNavBar] = useState(false);
    let lastScrollY = window.scrollY;
    const controlNavbar = () => {
        if (window.scrollY < window.innerHeight - 94) {
            setShow(true);
            setChangeNavBar(false);
        } else if (lastScrollY > window.scrollY) {
            setShow(true);
            setChangeNavBar(true);
        } else {
            setShow(false);
            setChangeNavBar(false);
        }
        lastScrollY = window.scrollY;
    };
    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.removeEventListener("scroll", controlNavbar);
        };
    });
    return (
        <header
            className="header"
            style={{
                position: "fixed",
                left: "0",
                top: "0",
                right: "0",
                backgroundColor: `${changeNavBar ? "#EEEEED" : "transparent"}`,
                opacity: `${show ? "1" : "0"}`,
                transition: `${
                    show
                        ? "opacity 0.5s ease-in"
                        : "opacity 0.5s ease-in, display 1s ease-in 1s"
                }`,
                display: `${show ? "block" : "none"}`,
            }}
        >
            <TopNavBar changeNavBar={changeNavBar} />
            <NavBar
                changeNavBar={changeNavBar}
                theme={props.theme}
                setTheme={props.setTheme}
            />
        </header>
    );
};
