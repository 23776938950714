import { useTranslation } from "react-i18next";
import { AI2, DevOps2, IoT2, IT2, Mobile2, Web2 } from "../../assets/expertise";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

export const Expertise = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <section className="section container container__data">
            <h3 className="section__title">{t("expertise.title")}</h3>
            <p className="section__description">{t("expertise.subtitle")}</p>
            <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}
                className="expertise__info"
            >
                <Link
                    to="/expertise/web-development"
                    className="offering__data"
                >
                    <img
                        src={Web2}
                        alt="what-we-offer"
                        className="offering__data-image"
                        id="cube-1"
                    />
                    <div className="offering__data-title-wrapper">
                        <span className="offering__data-title">
                            {t("expertise.1")}
                        </span>
                    </div>
                </Link>
                <Link
                    to="/expertise/mobile-development"
                    className="offering__data"
                >
                    <img
                        src={Mobile2}
                        alt="what-we-offer"
                        className="offering__data-image"
                        id="cube-2"
                    />
                    <div className="offering__data-title-wrapper">
                        <span className="offering__data-title">
                            {t("expertise.2")}
                        </span>
                    </div>
                </Link>
                <Link
                    to="/expertise/iot-development"
                    className="offering__data"
                >
                    <img
                        src={IoT2}
                        alt="what-we-offer"
                        className="offering__data-image"
                        id="cube-2"
                    />
                    <div className="offering__data-title-wrapper">
                        <span className="offering__data-title">
                            {t("expertise.3")}
                        </span>
                    </div>
                </Link>
                <Link
                    to="/expertise/dedicated-it-systems"
                    className="offering__data"
                >
                    <img
                        src={IT2}
                        alt="what-we-offer"
                        className="offering__data-image"
                        id="cube-1"
                    />
                    <div className="offering__data-title-wrapper">
                        <span className="offering__data-title">
                            {t("expertise.4")}
                        </span>
                    </div>
                </Link>
                <Link to="/expertise/devops" className="offering__data">
                    <img
                        src={DevOps2}
                        alt="what-we-offer"
                        className="offering__data-image"
                        id="cube-2"
                    />
                    <div className="offering__data-title-wrapper">
                        <span className="offering__data-title">
                            {" "}
                            {t("expertise.5")}
                        </span>
                    </div>
                </Link>
                <Link to="/expertise/ai" className="offering__data">
                    <img
                        src={AI2}
                        alt="what-we-offer"
                        className="offering__data-image"
                        id="cube-2"
                    />
                    <div className="offering__data-title-wrapper">
                        <span className="offering__data-title">
                            {t("expertise.6")}
                        </span>
                    </div>
                </Link>
            </motion.div>
        </section>
    );
};
