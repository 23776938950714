import React from "react";
import "./index.scss";
export const NotFound = () => {
    return (
        <div className="notfound">
            <div className="digit" title="404">
                404
            </div>
        </div>
    );
};
