import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import { Support1, Support2, Support3, Support4 } from "../../assets/support";

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

export const Support = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <section className="section container container__data">
            <h3 className="section__title"> {t("solution.support.title")}</h3>
            <p className="section__description">
                {t("solution.support.subtitle")}
            </p>
            <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}
                className="support__info"
            >
                <div className="support__data">
                    <img
                        src={Support1}
                        alt="what-we-offer"
                        className="support__data-image"
                    />
                    <div className="support__data-title-wrapper">
                        <span className="support__data-title">
                            {t("solution.support.individual")}
                        </span>
                        <span className="support__data-subtitle">
                            {t("solution.support.individual.subtitle")}
                        </span>
                    </div>
                </div>
                <div className="support__data">
                    <img
                        src={Support2}
                        alt="what-we-offer"
                        className="support__data-image"
                    />
                    <div className="support__data-title-wrapper">
                        <span className="support__data-title">
                            {" "}
                            {t("solution.support.startups")}
                        </span>
                        <span className="support__data-subtitle">
                            {t("solution.support.startups.subtitle")}
                        </span>
                    </div>
                </div>
                <div className="support__data">
                    <img
                        src={Support3}
                        alt="what-we-offer"
                        className="support__data-image"
                    />
                    <div className="support__data-title-wrapper">
                        <span className="support__data-title">
                            {t("solution.support.sme")}
                        </span>
                        <span className="support__data-subtitle">
                            {t("solution.support.sme.subtitle")}
                        </span>
                    </div>
                </div>
                <div className="support__data">
                    <img
                        src={Support4}
                        alt="what-we-offer"
                        className="support__data-image"
                    />
                    <div className="support__data-title-wrapper">
                        <span className="support__data-title">
                            {" "}
                            {t("solution.support.company")}
                        </span>
                        <span className="support__data-subtitle">
                            {t("solution.support.company.subtitle")}
                        </span>
                    </div>
                </div>
            </motion.div>
        </section>
    );
};
