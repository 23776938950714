import { useTranslation } from "react-i18next";

import { Value1, Value2, Value3, Value4 } from "../../assets/values";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

export const Values = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <section className="section container container__data">
            <div>
                <h3 className="section__title"> {t("about.values.title")}</h3>
                <p className="section__description">
                    {t("about.values.subtitle")}
                </p>
            </div>
            <div className="history-info">{t("about.values.desc")}</div>
            <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}
                className="support__info"
            >
                <div className="support__data">
                    <img
                        src={Value1}
                        alt="what-we-offer"
                        className="support__data-image"
                    />
                    <div className="support__data-title-wrapper">
                        <span className="support__data-title">
                            {" "}
                            {t("about.values.1")}
                        </span>
                        <span className="support__data-subtitle">
                            {t("about.values.1.desc")}
                        </span>
                    </div>
                </div>
                <div className="support__data">
                    <img
                        src={Value2}
                        alt="what-we-offer"
                        className="support__data-image"
                    />
                    <div className="support__data-title-wrapper">
                        <span className="support__data-title">
                            {t("about.values.2")}
                        </span>
                        <span className="support__data-subtitle">
                            {t("about.values.2.desc")}
                        </span>
                    </div>
                </div>
                <div className="support__data">
                    <img
                        src={Value3}
                        alt="what-we-offer"
                        className="support__data-image"
                    />
                    <div className="support__data-title-wrapper">
                        <span className="support__data-title">
                            {t("about.values.3")}
                        </span>
                        <span className="support__data-subtitle">
                            {t("about.values.3.desc")}
                        </span>
                    </div>
                </div>
                <div className="support__data">
                    <img
                        src={Value4}
                        alt="what-we-offer"
                        className="support__data-image"
                    />
                    <div className="support__data-title-wrapper">
                        <span className="support__data-title">
                            {" "}
                            {t("about.values.4")}
                        </span>
                        <span className="support__data-subtitle">
                            {t("about.values.4.desc")}
                        </span>
                    </div>
                </div>
            </motion.div>
        </section>
    );
};
