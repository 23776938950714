import { HeroArea } from "../../components/heroArea";
// import { WhyUs } from "./WhyUs";
import { Offering } from "./Offering";
import { Expertise } from "../../components/expertise";
import { TechStack } from "./TechStack";
// import { Testimonials } from "./Testimonials";
import { CTA } from "../../components/cta";

export const Home = () => {
  return (
    <>
      <HeroArea />
      {/* <WhyUs /> */}
      <Offering />
      <Expertise />
      <TechStack />
      {/* <Testimonials /> */}
      <CTA />
    </>
  );
};
