import React, { useRef, useEffect } from "react";
import { HeroArea } from "../../components/heroArea";

import { AI as Ai } from "../../assets/expertise";
import { useTranslation } from "react-i18next";
import "./expertise.css";

export const AI = () => {
  const { t } = useTranslation();

  const section = useRef(null);
  useEffect(() => {
    section.current.scrollIntoView();
  }, []);
  return (
    <>
      <HeroArea />

      <div ref={section} className="service--container container">
        <h2>{t("service.ai.title1")}</h2>
        <div className="service--content">
          <p className="paragraph">{t("service.ai.paragraph1")}</p>
          <img src={Ai} alt="ai" className="service--img" />
        </div>
        <br />
        <div className="service--second--content">
          <h3>{t("service.methodology")}</h3>
          <h4>1 - {t("service.ai.title2")}</h4>
          <p className="paragraph">{t("service.ai.paragraph2")}</p>
          <h4>2 - {t("service.ai.title3")}</h4>
          <p className="paragraph">{t("service.ai.paragraph3")}</p>
          <h4>3 - {t("service.ai.title4")}</h4>
          <p className="paragraph">{t("service.ai.paragraph4")}</p>
          <h4>4 - {t("service.ai.title5")}</h4>
          <p className="paragraph">{t("service.ai.paragraph5")}</p>
          <h4>5 - {t("service.ai.title6")}</h4>
          <p className="paragraph">{t("service.ai.paragraph6")}</p>
        </div>
      </div>
    </>
  );
};
