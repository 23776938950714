import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { NotFound } from "./components/notFound";

import { Home } from "./pages/home";
import { Solution } from "./pages/solutions";
import { About } from "./pages/about";
import { Contact } from "./pages/contact";
import { Web } from "./pages/expertise/web";
import { Mobile } from "./pages/expertise/mobile";
import { IoT } from "./pages/expertise/iot";
import { Devops } from "./pages/expertise/devops";
import { AI } from "./pages/expertise/ai";
import { Dedicated } from "./pages/expertise/dedicated";

function App() {
    const [theme, setTheme] = useState("light");

    return (
        <>
            <div>
                <Header theme={theme} setTheme={setTheme} />
            </div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/solution" element={<Solution />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/expertise/web-development" element={<Web />} />
                <Route
                    path="/expertise/mobile-development"
                    element={<Mobile />}
                />
                <Route path="/expertise/iot-development" element={<IoT />} />
                <Route path="/expertise/devops" element={<Devops />} />
                <Route path="/expertise/ai" element={<AI />} />
                <Route
                    path="/expertise/dedicated-it-systems"
                    element={<Dedicated />}
                />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer theme={theme} />
        </>
    );
}

export default App;
