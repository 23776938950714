import { Link } from "react-router-dom";
import * as UnIcons from "@iconscout/react-unicons";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

const boxVariant = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.3, duration: 1 },
  },
  hidden: { opacity: 0, y: -100 },
};

export const CTA = () => {
  const { t } = useTranslation();
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);
  return (
    <motion.section
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      className="section container container__data cta__container"
    >
      <div className="cta-info">
        <h1 className="cta-title"> {t("cta.title")}</h1>
        <p className="cta-subtitle">{t("cta.subtitle")}</p>
      </div>
      <Button
        variant="contained"
        color="primary"
        component="button"
        sx={{
          padding: "0px",
          textDecoration: "none",
          backgroundColor: "transparent",
          borderColor: "transparent",
          borderWidth: "0px",
          borderRadius: "20px",
          boxShadow: "none",
          fontFamily: "inherit",
          fontWeight: "inherit",
          fontSize: "inherit",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "transparent",
            boxShadow: "none",
          },
          "&:active": {
            backgroundColor: "transparent",
            borderColor: "transparent",
          },
          "&:focus": {
            boxShadow: "none",
            outline: "none",
          },
        }}
      >
        <Link to="/contact" className="button button--flex">
          {t("navBar.contact")}
          <UnIcons.UilMessage className="button__icon" />
        </Link>
      </Button>
    </motion.section>
  );
};
