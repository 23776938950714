import { HeroArea } from "../../components/heroArea";
import { History } from "./History";
import { Values } from "./Values";
import { Team } from "./Team";
import { CTA } from "../../components/cta";

export const About = () => {
    return (
        <>
            <HeroArea />
            <History />
            <Values />
            <Team />
            <CTA />
        </>
    );
};
